import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Autoplay]);
export const initSwiper = () => {
    const reviewsSliderWrapper = document.querySelector('.reviews_wrapper .swiper-wrapper');
    const reviewsSlides = document.querySelectorAll('.reviews_wrapper.swiper-container .swiper-slide');
    const reviewsSliderButtons = document.querySelector('.reviews_wrapper.swiper-container .swiper-buttons_wrap');
    const handleResize = () => {
        console.log('resize');
        if(window.innerWidth < 1024){
            reviewsSliderButtons.style.display = 'block';
            reviewsSliderWrapper.style.justifyContent = 'normal';
        }else{
            reviewsSliderButtons.style.display = 'none';
            reviewsSliderWrapper.style.justifyContent = 'space-around';
        }
    }
    if(reviewsSlides.length <=3){
        handleResize();
        window.addEventListener('resize', handleResize);
    }
    const defaultSwiperOptions = {
        direction: 'horizontal',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        allowTouchMove: true,
        disabled: true,
        slidesPerView: 1,
        loop: true,
        spaceBetween: 30,
        observer: true,
        observeParents: true,
        resizeObserver: true,
        breakpoints: {
            640: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        }
    }
    const teachersSlider = new Swiper('.teachers_wrapper', defaultSwiperOptions);
    const reviewsSlider = new Swiper('.reviews_wrapper',
      {
          ...defaultSwiperOptions,
          loop: reviewsSlides.length >=3,
      });
    const galerySlider = new Swiper('.galery_wrapper', {
        direction: 'horizontal',
        slidesPerView: 1,
        // autoHeight:true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        allowTouchMove: true,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'col',
        // loop: true,
        spaceBetween: 30,
        observer: true,
        observeParents: true,
        resizeObserver: true,
        breakpoints: {
            640: {
                slidesPerView: 2,
                slidesPerColumn: 2,
                slidesPerColumnFill: 'col',
            },
            768: {
                slidesPerView: 3,
                slidesPerColumn: 2,
                slidesPerColumnFill: 'col',
            },
            1024: {
                slidesPerView: 4,
                slidesPerColumn: 2,
                slidesPerColumnFill: 'col',
            },
        }
    });
}
// {
//     slidesPerView: 1,
//         spaceBetween: 10,
//     // init: false,
//     pagination: {
//     el: '.swiper-pagination',
//         clickable: true,
// },
//     breakpoints: {
//         640: {
//             slidesPerView: 2,
//         },
//         768: {
//             slidesPerView: 4,
//         },
//         1024: {
//             slidesPerView: 3,
//         },
//     }
// }