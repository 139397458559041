import './pages/index'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { convertImages } from './utils/convert'
import { initSwiper } from './utils/swiper';
import { CustomSelect } from './utils/custom-select';
import { galeryModal } from './utils/galery';
import { forms } from './utils/forms';

function initAnimateTitle(elements){
  let letters = [...elements].map(e => e.innerHTML.split(''));
  letters = letters.map((e) => e.map((e, i) => {
    if(e === ' '){
      return `<span data-aos=\"clip-top-50\" data-aos-delay=\"${i * 20}\">${e}</span>`
    }
    return `<span style="display: inline-block" data-aos=\"clip-top-50\" data-aos-delay=\"${i * 20}\">${e}</span>`
  }))
  elements.forEach((e, i) => {
    let words = letters[i].join('').split(' </span>');
    words = words.map(e => e + ' </span>');
    words = words.map(e => `<span style="white-space: nowrap;">${e}</span>`);
    e.innerHTML = words.join('') + '</span>';
  });
}
window.addEventListener('load', AOS.refresh);

document.addEventListener('DOMContentLoaded', function() {


  AOS.refresh();
  const modalBg = document.querySelector('.modal_bg');
  const allModals = document.querySelectorAll('.modal_box');
  const abutUsVideoPlayBtn = document.querySelector('.about_us_play_btn');
  const courseProgramItems = document.querySelectorAll('.course_program_item_title');
  const trialLessonBtn = document.querySelectorAll('.open-modal');
  const closeModalBtn = document.querySelectorAll('.close-modal');
  const modal_1 = document.querySelector('.modal_1');
  const successModal = document.querySelector('.modal_2');
  const franchModal = document.querySelector('.modal_4');
  const franchModalBtns = document.querySelectorAll('.open-franch-modal');
  const animateTitles = document.querySelectorAll('.animate_title');
  const reviewPlayBtns = document.querySelectorAll('.reviews_item_play-link');
  forms(modal_1, franchModal, successModal);
  initAnimateTitle(animateTitles);
  AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
    offset: 120,
    delay: 0,
    duration: 1200,
    easing: 'ease',
    once: false,
    mirror: false,
    anchorPlacement: 'top-bottom',
  });
  initSwiper();
  galeryModal();
  closeModalBtn.forEach(e => {
    e.addEventListener('click', () => {
      modalBg.classList.remove('active');
      document.body.classList.remove('no-scroll');
      allModals.forEach((e) => {
        e.classList.remove('active');
      })
    })
  })
  reviewPlayBtns.forEach(e => {
    e.addEventListener('click', (e) => {
      e.preventDefault();
      e.currentTarget.parentNode.classList.toggle('active');
    })
  })
  if(abutUsVideoPlayBtn){
    abutUsVideoPlayBtn.addEventListener('click', (e) => {
      e.target.parentNode.classList.toggle('active');
    });
  }
  franchModalBtns.forEach(el => {
    el.addEventListener('click', (e) => {
      modalBg.classList.toggle('active');
      document.body.classList.toggle('no-scroll');
      franchModal.classList.toggle('active');
    })
  })
  modalBg.addEventListener('click', (e) => {
    if(e.target.classList.contains('modal_bg')){
      modalBg.classList.remove('active');
      document.body.classList.remove('no-scroll');
      allModals.forEach((e) => {
        e.classList.remove('active');
      })
    }
  })
  courseProgramItems.forEach(e => {
    e.addEventListener('click', (e) => {
      e.target.parentNode.classList.toggle('active')
    })
  })
  trialLessonBtn.forEach(e => {
    e.addEventListener('click', (e) => {
      modalBg.classList.toggle('active');
      document.body.classList.toggle('no-scroll');
      modal_1.classList.toggle('active');
    })
  })

  const select1 = new CustomSelect('#select-1');
})
