export const galeryModal = () => {
	const galyeryItems = document.querySelectorAll('.galery_item');
	const modalBg = document.querySelector('.modal_bg');
	const modal_3 = document.querySelector('.modal_3');
	const galeryModalImg = document.querySelector('.galery_modal_img');
	const onGaleryItemClick = (e) => {
		galeryModalImg.innerHTML = `<img src="${e.target.currentSrc}" alt="gallery"/>`;
		console.log(e.target.currentSrc);
		modalBg.classList.toggle('active');
		document.body.classList.toggle('no-scroll');
		modal_3.classList.toggle('active');
	}
	galyeryItems.forEach(e => {
		e.addEventListener('click', onGaleryItemClick);
	})
}