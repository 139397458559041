import IMask from 'imask';
import '../lib/jquery.maskedinput.min';

const fetchCall = (url, body) => {
	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			"Accept": "application/json,text/*"
		},
		body: JSON.stringify(body),
	})
}
const handleInputErrors = (inputs) => {
	return inputs.every((el) => {
		el.addEventListener('input', (e) => {
			e.target.parentNode.parentNode.classList.remove('error');
		})
		if(!el.value){
			el.parentNode.parentNode.classList.add('error');
			return;
		}else{
			return true;
		}
	})
}
const handleCitySelectError = (citySelect) => {
	citySelect.addEventListener('click', () => citySelect.parentNode.classList.remove('error'));
	if(citySelect.getAttribute('data-index') === '-1'){
		citySelect.parentNode.classList.add('error');
		return;
	}
	return true;
}

const handleFormError = (form, success) => {
	if(form && !success){
		form.classList.add('error');
		form.querySelector('.form_error_message').innerHTML = 'ошибка!';
	}else{
		form.classList.remove('error');
		form.querySelector('.form_error_message').innerHTML = '';
	}
}

export const forms = (feedBackModal, franchModal, successModal) => {

	const feedbackForm = document.getElementById('feedbackForm');
	const franchiseForm = document.getElementById('feedbackFormFranchise');

	// const handleClickCity = () => (e) => {
	// 	var slug = e.target.getAttribute('data-slug');
	// 	var maskOptions = {};
	// 	if (slug === 'kyiv' || slug === 'kharkiv' || slug === 'odessa' || slug === 'dnepr' || slug === 'vinnica' || slug === 'chernovcy') {
	// 		maskOptions = {
	// 			mask: '+{38} (000) 000 00 00'
	// 		};
	// 	} else if (slug === 'kishinev') {
	// 		maskOptions = {
	// 			mask: '+{373} 00000000'
	// 		};
	// 	} else if (slug === 'warsaw') {
	// 		maskOptions = {
	// 			mask: '+{48} 000 000 00 00'
	// 		};
	// 	}
	//
	// 	const phoneInputs = document.querySelectorAll('[data-phone-input]');
	// 	phoneInputs.forEach(el => {
	// 		let mask = IMask(el, maskOptions);
	// 	})
	// }
	//
	// const citySel = feedbackForm.querySelectorAll('.select__option');
	// citySel.forEach(function (element) {
	// 	element.addEventListener('click', handleClickCity());
	// })


	const handleSubmit = (type) => (e) => {
		e.preventDefault();
		const nameInput = e.target.elements["name"];
		const phoneInput = e.target.elements["phone"];
		const url = e.target.getAttribute('action');

		if(!handleInputErrors([nameInput, phoneInput])) return;

		const body = {
			name: e.target.elements["name"].value,
			phone: e.target.elements["phone"].value,
		};
		if(type === 'feedbackForm'){
			const citySelect = feedbackForm.querySelector('[data-select-city]');
			if(handleCitySelectError(citySelect)){
				body.city = citySelect.innerHTML;
				fetchCall(url, body).then(res => {
					if(res.ok){
						feedBackModal.classList.remove('active');
						successModal.classList.add('active');
						handleFormError(feedbackForm, 1);
					} else {
						handleFormError(feedbackForm);
					}
				}).catch(error => {
					console.dir(error);
				})
			}
		}else if(type === 'franchiseForm'){
			fetchCall(url, body).then(res => {
				if(res.ok){
					franchModal.classList.remove('active');
					successModal.classList.add('active');
					handleFormError(franchiseForm, 1);
				}else{
					handleFormError(franchiseForm);
				}
			}).catch(error => {
				console.dir(error);
			});
		}
	}
	feedbackForm.addEventListener('submit', handleSubmit('feedbackForm'));
	franchiseForm.addEventListener('submit', handleSubmit('franchiseForm'));
}